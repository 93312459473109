#root * {
  box-sizing: border-box; }

.ReactLayer {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  overflow: hidden;
  pointer-events: none; }
  .ReactLayer > .background {
    background-color: #222;
    position: absolute;
    width: 100%;
    height: 100%; }

.ReactLayer button {
  display: block;
  color: transparent;
  background-color: transparent;
  background-size: 100% 100%;
  width: 50px;
  height: 50px;
  text-align: center;
  border: none;
  cursor: pointer;
  pointer-events: all; }
  .ReactLayer button[disabled] {
    filter: brightness(50%); }
  .ReactLayer button:focus {
    outline: none; }
  .ReactLayer button.closeButton {
    background-image: url("images/button-close.png"); }
  .ReactLayer button.pauseButton {
    background-image: url("images/button-pause.png"); }
  .ReactLayer button.playButton {
    background-image: url("images/button-play.png"); }
  .ReactLayer button.mobilePlayButton {
    background-image: url("images/mobilePlay.svg"); }
  .ReactLayer button.previousButton {
    background-image: url("images/button-previous.png"); }
  .ReactLayer button.nudgeBackButton {
    background-image: url("images/NOVA_video-button_back.png"); }

/*!
 * Web Fonts from Fontspring.com
 *
 * All OpenType features and all extended glyphs have been removed.
 * Fully installable fonts can be purchased at http://www.fontspring.com
 *
 * The fonts included in this stylesheet are subject to the End User License you purchased
 * from Fontspring. The fonts are protected under domestic and international trademark and
 * copyright law. You are prohibited from modifying, reverse engineering, duplicating, or
 * distributing this font software.
 *
 * (c) 2010-2020 Fontspring
 *
 *
 *
 *
 * The fonts included are copyrighted by the vendor listed below.
 *
 * Vendor:      Ana's Fonts
 * License URL: https://www.fontspring.com/licenses/anas-fonts/webfont
 *
 *
 */
/*
 @font-face {
  font-family: 'workshop-marker';
  src: url('fonts/workshop_marker-webfont.woff2') format('woff2'),
        url('fonts/workshop_marker-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'xenera';
  src: url('fonts/xenara_rg-webfont.woff2') format('woff2'),
        url('fonts/xenara_rg-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face{
  font-family: "program";
  src: url('fonts/programot-book-webfont.woff2') format('woff2'),
        url('fonts/programot-book-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face{
  font-family: "program";
  src: url('fonts/programot-bookitalic-webfont.woff2') format('woff2'),
        url('fonts/programot-bookitalic-webfont.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face{
  font-family: "amateur-typewriter";
  src: url('fonts/amateur-webfont.woff2') format('woff2'),
        url('fonts/amateur-webfont.woff') format('woff');
  font-weight: 400;
  letter-spacing: -1px;
}

.tk-xenera { font-family: "xenera",sans-serif; }
.tk-program { font-family: "program",sans-serif; }

.typewriter{
  font-family: "amateur-typewriter", serif;
  font-weight: 400;
  font-style: normal;
}

.workshop-marker{
  font-family: 'workshop-marker', cursive;
  font-weight:400;
  font-style: normal;
}
*/
/* following is for exoplanets ui */
@font-face {
  font-family: "Roboto";
  src: url("fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "Roboto";
  src: url("fonts/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: "Roboto";
  src: url("fonts/Roboto-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: "Exo2";
  src: url("fonts/Exo2-Regular.ttf") format("truetype");
  font-style: normal; }

@font-face {
  font-family: "Exo2medium";
  src: url("fonts/Exo2-SemiBold.ttf") format("truetype");
  font-style: normal; }

@font-face {
  font-family: "Exo2bold";
  src: url("fonts/Exo2-ExtraBold.ttf") format("truetype");
  font-style: normal; }

.HeadsUpDisplay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  overflow: hidden;
  pointer-events: none;
  font-size: 1.5em;
  font-family: xenera, sans-serif;
  font-weight: 400;
  font-style: normal;
  transition: background-color 0.5s; }
  .HeadsUpDisplay .background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #222;
    z-index: 2; }
  .HeadsUpDisplay .foreground {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3; }
  .HeadsUpDisplay header {
    display: flex;
    position: relative;
    flex-direction: row;
    padding: 10px;
    z-index: 15;
    transition: opacity 0.5s; }
    .HeadsUpDisplay header.hide-all-but-last > *:not(:last-child) {
      visibility: hidden; }
  .HeadsUpDisplay .top-nav-container {
    display: flex; }
  .HeadsUpDisplay .exo-logo {
    width: 200px;
    height: 50px;
    margin-right: 90px; }
    .HeadsUpDisplay .exo-logo img {
      width: 100%; }
  .HeadsUpDisplay footer {
    display: flex;
    position: fixed;
    flex-direction: row;
    padding: 10px;
    z-index: 15;
    bottom: 0px;
    width: 100%;
    opacity: 0.8;
    transition: opacity 0.5s; }
    .HeadsUpDisplay footer.hide-all-but-last > *:not(:last-child) {
      visibility: hidden; }
  .HeadsUpDisplay .journal {
    background-image: url("images/HUDjournal.svg");
    height: 50px;
    width: 100px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 2px;
    font-size: 0.9em;
    pointer-events: all;
    cursor: pointer;
    border-bottom-left-radius: 30px;
    border-top-left-radius: 30px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px; }
    .HeadsUpDisplay .journal.notification {
      animation: notification-repeat 1.5s infinite; }

@keyframes notification-repeat {
  4%,
  55% {
    /*background-color: lighten(saturate($ticket-button-active-bgcolor-default, 90%), 2%);*/
    box-shadow: 0 0 0 0 rgba(104, 196, 188, 0.8), inset 0 0 1rem #68c4bc, inset 0 0 0 0 #68c4bc; }
  20%,
  45% {
    background-color: #017474;
    box-shadow: 0 0 25px 3px rgba(104, 196, 188, 0.8), inset 0 0 0 3px rgba(255, 255, 255, 0.5), inset 0 0 25px 8px white; }
  70% {
    /*background-color: $ticket-button-active-bgcolor-default;*/
    box-shadow: 0 0 0 0 rgba(104, 196, 188, 0.8), inset 0 0 1rem #68c4bc, inset 0 0 0 0 #68c4bc;
    box-shadow: 0 0 0 0 rgba(104, 196, 188, 0.8), inset 0 0 0 0 #68c4bc; } }
    .HeadsUpDisplay .journal:hover {
      animation: notification-glow 0.4s linear;
      animation-fill-mode: forwards; }

@keyframes notification-glow {
  0% {
    box-shadow: 0 0 0 0 rgba(104, 196, 188, 0.8), inset 0 0 1rem #68c4bc, inset 0 0 0 0 #68c4bc; }
  4%,
  100% {
    background-color: #017474;
    box-shadow: 0 0 25px 3px rgba(104, 196, 188, 0.8), inset 0 0 0 3px rgba(255, 255, 255, 0.5), inset 0 0 25px 8px white; } }
    .HeadsUpDisplay .journal .container {
      width: 100%;
      height: 100%;
      padding: 10px 0; }
    .HeadsUpDisplay .journal .count {
      color: #99f2db;
      display: inline-block;
      width: 100%;
      text-align: center;
      padding-left: 40px; }
  .HeadsUpDisplay .spacer {
    flex-grow: 1; }
  .HeadsUpDisplay .globe {
    background-image: url("images/HUDglobe.svg");
    height: 50px;
    width: 50px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-left: 20px;
    pointer-events: all;
    cursor: pointer; }
  .HeadsUpDisplay .logo {
    height: 50px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-left: 20px;
    margin-right: 60px;
    pointer-events: all;
    cursor: pointer; }
  .HeadsUpDisplay .muteButtonExo {
    display: flex;
    width: 60px;
    background: no-repeat;
    border: none;
    border-radius: 6px;
    background-color: #17143c;
    color: #0edbc2;
    font-size: 17px;
    padding: 5px 15px;
    font-family: Exo2;
    font-weight: 600;
    cursor: pointer;
    pointer-events: all;
    overflow: hidden;
    transition: all 0.12s; }
    .HeadsUpDisplay .muteButtonExo p {
      width: auto;
      min-width: 105px;
      padding: 0;
      margin: 0;
      padding-left: 17px;
      line-height: 1.5rem; }
  .HeadsUpDisplay .muteButtonExo:hover {
    width: 160px; }
  .HeadsUpDisplay .muteButtonExo:active {
    outline: none; }
  .HeadsUpDisplay .muteButtonExo:focus {
    outline: none; }
  .HeadsUpDisplay .muteIcon {
    height: 28px;
    cursor: pointer; }
  .HeadsUpDisplay .mute-instructions {
    position: absolute;
    left: 0;
    top: -20px;
    margin: 5px;
    font-size: 1.1rem;
    transform: translateY(-100%); }
  .HeadsUpDisplay .muteButton {
    content: url("images/mute_button.svg");
    width: 40px;
    pointer-events: all;
    cursor: pointer; }
  .HeadsUpDisplay .unmuteButton {
    content: url("images/unmute_button.svg");
    width: 40px;
    pointer-events: all;
    cursor: pointer; }
  .HeadsUpDisplay .magicWindowIcon {
    width: 45px;
    height: 45px;
    pointer-events: all;
    cursor: pointer; }
  .HeadsUpDisplay .hamburger-icon {
    display: none;
    padding: 0px;
    width: 50px;
    height: 50px;
    cursor: pointer;
    pointer-events: all; }
  .HeadsUpDisplay .bottom-left {
    position: absolute;
    bottom: 10px;
    left: 10px;
    display: flex;
    align-items: flex-end;
    color: #99f2db; }
    .HeadsUpDisplay .bottom-left > * {
      margin-right: 10px; }
  .HeadsUpDisplay .bottom-right {
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: flex;
    align-items: flex-end;
    flex-direction: column; }
    .HeadsUpDisplay .bottom-right > * {
      margin-top: 5px; }
    .HeadsUpDisplay .bottom-right .muteIcon {
      display: none; }

.AboutPage {
  pointer-events: all;
  cursor: pointer; }
  .AboutPage .content {
    max-width: 80%;
    margin: 0 auto; }
    .AboutPage .content img {
      width: 100%; }

.SceneSelector {
  position: relative; }
  .SceneSelector a {
    text-decoration: none; }
  .SceneSelector .dropdown {
    position: absolute;
    z-index: 1;
    background: #397d80;
    background: linear-gradient(0deg, #397d80 0%, #222226 90%);
    width: 85.7%;
    left: 8.09%;
    padding-top: 15px;
    transition: height 1s;
    pointer-events: all;
    overflow: hidden;
    cursor: pointer; }
    .SceneSelector .dropdown a + a {
      border-top: solid thin #71a1a9;
      display: block; }

.SceneSelectorDisplay {
  background-image: url("images/HUDlocation.svg");
  color: #a7a72d;
  text-transform: uppercase;
  background-repeat: no-repeat;
  height: 50px;
  width: 420px;
  background-size: 100% 100%;
  pointer-events: all;
  cursor: pointer; }
  .SceneSelectorDisplay .container {
    width: 100%;
    height: 100%;
    padding: 10px 15px;
    display: flex;
    font-size: 0.86em; }
  .SceneSelectorDisplay .moduleId {
    color: #1e5354;
    text-align: center;
    width: 68px;
    padding-left: 4px; }
  .SceneSelectorDisplay .moduleName {
    margin-left: 10px; }

.SceneSelectorOption {
  color: #b8e7f4;
  font-size: 1rem;
  font-family: "program", sans-serif;
  padding: 8px;
  pointer-events: all;
  cursor: pointer; }
  .SceneSelectorOption:hover {
    color: #dbf7ff; }
  .SceneSelectorOption .moduleName {
    text-align: left;
    margin-left: 10px; }
  .SceneSelectorOption .moduleId {
    width: 2.75rem;
    margin-right: 10px;
    text-align: right;
    width: 32px; }
  .SceneSelectorOption > * {
    display: inline-block; }

@media screen and (min-width: 1150px) and (max-height: 840px) {
  .content {
    top: -208px;
    transform: scale(0.85); } }

@media screen and (max-height: 570px) {
  .content {
    top: -90px;
    transform: scale(0.87); }
  .HeadsUpDisplay {
    font-size: 1em; }
    .HeadsUpDisplay header .globe {
      width: 40px;
      height: 40px; }
    .HeadsUpDisplay header .logo {
      height: 40px; }
    .HeadsUpDisplay .journal {
      height: 40px; }
      .HeadsUpDisplay .journal .container {
        padding: 8px 0; }
      .HeadsUpDisplay .journal .count {
        font-size: 1.05rem; }
  .SceneSelector {
    position: relative;
    font-size: 1.1em; }
    .SceneSelector .SceneSelectorDisplay {
      height: 40px;
      width: 300px; }
      .SceneSelector .SceneSelectorDisplay .container {
        padding: 10px; }
      .SceneSelector .SceneSelectorDisplay .moduleId {
        width: 11%;
        margin-right: 5px; }
    .SceneSelector .dropdown {
      width: 90%; }
    .SceneSelector .SceneSelectorOption {
      font-size: 0.8rem; } }

@media (max-height: 400px) {
  .content {
    top: -90px;
    transform: scale(0.87); }
  .HeadsUpDisplay {
    font-size: 1em; }
    .HeadsUpDisplay.mobile-hud-open {
      background: rgba(0, 0, 0, 0.8); }
      .HeadsUpDisplay.mobile-hud-open header {
        display: flex;
        opacity: 1; }
    .HeadsUpDisplay header {
      opacity: 1; }
      .HeadsUpDisplay header .globe {
        width: 35px;
        height: 35px; }
      .HeadsUpDisplay header .logo {
        height: 35px; }
    .HeadsUpDisplay .journal {
      height: 35px; }
      .HeadsUpDisplay .journal .container {
        padding: 8px 0;
        padding: 5px; }
      .HeadsUpDisplay .journal .count {
        font-size: 1.1rem; }
    .HeadsUpDisplay .hamburger-icon {
      display: block;
      width: 35px;
      height: 35px; }
    .HeadsUpDisplay .magicWindowIcon {
      width: 35px;
      height: 35px; }
    .HeadsUpDisplay .bottom-right {
      flex-direction: row; }
      .HeadsUpDisplay .bottom-right .muteIcon,
      .HeadsUpDisplay .bottom-right .qualitySelector img {
        margin-left: 8px; }
      .HeadsUpDisplay .bottom-right .muteIcon {
        display: block; }
      .HeadsUpDisplay .bottom-right .qualitySelector {
        z-index: 1000; }
  .SceneSelector {
    position: relative;
    font-size: 1em; }
    .SceneSelector .SceneSelectorDisplay {
      height: 35px;
      width: 300px; }
      .SceneSelector .SceneSelectorDisplay .container {
        padding: 10px; }
      .SceneSelector .SceneSelectorDisplay .moduleId {
        padding-left: 12px;
        width: 16%;
        margin-right: 5px; }
      .SceneSelector .SceneSelectorDisplay .moduleName {
        margin-left: 9px; }
    .SceneSelector .dropdown {
      width: 89%;
      left: 5%; }
    .SceneSelector .SceneSelectorOption {
      font-size: 0.8rem;
      padding: 8px; } }

.manual-toggle-button {
  pointer-events: all;
  margin-right: 85px;
  padding: 6px;
  cursor: pointer;
  background-color: #17143c;
  width: 64px;
  height: 37px;
  border-radius: 6px;
  text-align: center;
  vertical-align: middle; }

.manual-training img {
  width: 23px; }

.hint-toggle-button {
  pointer-events: all;
  padding: 6px;
  cursor: pointer;
  background-color: #17143c;
  width: 74px;
  height: 62px;
  border-radius: 6px;
  text-align: center;
  vertical-align: middle; }

.hint-toggle-button img {
  height: 109%;
  margin-top: -1px; }

@media screen and (max-width: 960px) {
  .manual-toggle-button, #pbs-userinfo-btn {
    transform: translate(0px, 0px) scale(0.75); }
  .muteButtonExo, .hint-toggle-button, .videoQualityButton {
    transform: translate(0px, 5px) scale(0.8); } }

.VideoPlayer {
  pointer-events: all;
  position: relative;
  margin: 150px auto;
  display: flex;
  justify-content: center; }
  .VideoPlayer .fullscreen-blur {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(12px); }
  .VideoPlayer .container {
    position: relative;
    z-index: 1; }
  .VideoPlayer .caption.external {
    display: none; }
  .VideoPlayer video, .VideoPlayer .player-content-frame {
    width: 1080px;
    height: 608px;
    display: block;
    background-color: black; }
    .VideoPlayer video:focus, .VideoPlayer .player-content-frame:focus {
      outline: none; }
  .VideoPlayer .overlay {
    position: absolute;
    top: -10.28%;
    left: -1.02%;
    width: 102.24%;
    height: 121.71%;
    pointer-events: none;
    content: url("images/VideoWindow.svg"); }
  .VideoPlayer .header {
    font-size: 1.3em;
    font-family: xenera, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #99f2db;
    position: absolute;
    top: -8.22%;
    left: 80px;
    width: calc(100% - calc(2*80px));
    height: 8.22%;
    display: table;
    margin: 0;
    padding: 0;
    letter-spacing: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center; }
    .VideoPlayer .header p {
      display: inline-block;
      vertical-align: middle;
      margin: 0;
      padding: 0; }
    .VideoPlayer .header .title {
      color: #a7a72d;
      text-transform: uppercase;
      padding-right: 20px; }
    .VideoPlayer .header .subtitle {
      text-transform: capitalize; }
  .VideoPlayer .caption {
    font-size: 1.5em;
    font-family: program, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #99f2db;
    position: absolute;
    left: 110px;
    width: calc(100% - calc(2*110px));
    /*    height: 57px;
    bottom: -57px;*/
    height: 9.375%;
    bottom: -9.375%;
    display: table;
    margin: 0;
    padding: 0;
    letter-spacing: 2px;
    pointer-events: none; }
    .VideoPlayer .caption p {
      display: table-cell;
      text-align: center;
      vertical-align: middle;
      margin: 0;
      padding: 0;
      line-height: normal; }
  .VideoPlayer button {
    position: absolute;
    z-index: 1; }
    .VideoPlayer button.closeButton {
      top: -50px;
      right: -16px; }
    .VideoPlayer button.pauseButton {
      left: -6px;
      bottom: -26px; }
    .VideoPlayer button.playButton {
      left: -6px;
      bottom: -26px; }
    .VideoPlayer button.previousButton {
      left: -6px;
      bottom: -26px; }
    .VideoPlayer button.nudgeBackButton {
      right: -6px;
      bottom: -26px; }
  .VideoPlayer .ProgressBar {
    position: absolute;
    bottom: 0px;
    left: 30px;
    width: calc(100% - 32px);
    height: 3px; }
  .VideoPlayer .question {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
    .VideoPlayer .question .container {
      position: relative;
      width: 80%;
      height: 30%;
      font-size: 1.5em;
      font-family: xenera, sans-serif;
      font-weight: 400;
      font-style: normal;
      color: #99f2db;
      margin: 20% auto;
      letter-spacing: 2px;
      pointer-events: none;
      background-color: #222; }
    .VideoPlayer .question .text {
      width: 100%;
      height: 100%;
      display: table; }
    .VideoPlayer .question p {
      display: table-cell;
      text-align: center;
      vertical-align: middle;
      margin: 0;
      padding: 0; }
    .VideoPlayer .question .controls {
      height: 30px;
      width: 100%; }
      .VideoPlayer .question .controls .playButton {
        left: calc(50% + 70px);
        bottom: -80px;
        width: 100px;
        height: 100px; }
      .VideoPlayer .question .controls .previousButton {
        left: calc(50% - 170px);
        bottom: -80px;
        width: 100px;
        height: 100px; }

.ProgressBar .background {
  background-image: url("images/PlaybackBar.svg");
  height: 100%;
  background-size: cover; }

.ProgressBar .bar {
  background-image: url("images/PlaybackBarViewed.svg");
  background-size: cover;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0; }

.ProgressBar .playhead {
  background-image: url("images/Playhead.svg");
  width: 12px;
  height: 12px;
  position: absolute;
  left: 0;
  bottom: -4.5px;
  pointer-events: all;
  cursor: pointer; }

.ProgressBar .track {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: all;
  cursor: pointer;
  height: 100%;
  width: 100%; }

.VideoPlayer.fullscreen {
  width: 100%;
  height: 100%;
  margin: 0; }
  .VideoPlayer.fullscreen .container {
    width: 100%;
    height: 100%; }
  .VideoPlayer.fullscreen .overlay {
    display: none; }
  .VideoPlayer.fullscreen video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: black; }
  .VideoPlayer.fullscreen .header {
    position: absolute;
    bottom: 44px;
    top: auto;
    margin: 0;
    padding: 0;
    left: 130px;
    width: calc(100% - calc(2*130px));
    height: 33px;
    z-index: 10;
    font-size: 1em; }
  .VideoPlayer.fullscreen .caption {
    position: absolute;
    bottom: 0.0em;
    margin: 0;
    padding: 33px 0 0 0;
    left: 16%;
    width: calc(100% - calc(2*16%));
    height: 79px;
    background-image: url("images/FullscreenVideoTitleAndCC.svg");
    background-size: 100% 100%;
    font-size: 1.2em; }
    .VideoPlayer.fullscreen .caption p {
      position: relative;
      height: 100%;
      padding: 0px 8%; }
  .VideoPlayer.fullscreen .ProgressBar {
    height: 2px;
    bottom: 92px;
    left: 20px;
    width: calc(100% - calc(2*20px)); }
    .VideoPlayer.fullscreen .ProgressBar .playhead {
      height: 10px;
      width: 10px;
      bottom: -4px; }
  .VideoPlayer.fullscreen .controls button {
    bottom: 69px; }
  .VideoPlayer.fullscreen .controls .closeButton {
    top: 70px;
    right: 10px; }
  .VideoPlayer.fullscreen .controls .pauseButton, .VideoPlayer.fullscreen .controls .playButton {
    left: 10px; }
  .VideoPlayer.fullscreen .controls .nudgeBackButton {
    right: 10px; }

.VideoPlayer.hotspot {
  height: 100%;
  width: 100%;
  margin: 0; }
  .VideoPlayer.hotspot .container {
    position: absolute; }
  .VideoPlayer.hotspot .overlay {
    position: absolute;
    pointer-events: none;
    height: 290px;
    width: 468px;
    top: -28px;
    width: 100.7%;
    height: 121.71%;
    top: -14.1%;
    left: -0.42%;
    content: url("images/VideoWindowHotspot.svg"); }
  .VideoPlayer.hotspot .ProgressBar {
    height: 2px; }
    .VideoPlayer.hotspot .ProgressBar .playhead {
      height: 10px;
      width: 10px;
      bottom: -4px; }
  .VideoPlayer.hotspot .caption {
    display: none; }
  .VideoPlayer.hotspot .caption.external {
    display: table;
    font-size: 1.6em; }
  .VideoPlayer.hotspot button {
    width: 40px;
    height: 40px; }
    .VideoPlayer.hotspot button.closeButton {
      top: -30px;
      right: -3px; }
    .VideoPlayer.hotspot button.pauseButton {
      left: -8px;
      bottom: -16px; }
    .VideoPlayer.hotspot button.playButton {
      left: -8px;
      bottom: -16px; }
    .VideoPlayer.hotspot button.nudgeBackButton {
      right: -8px;
      bottom: -16px; }
  .VideoPlayer.hotspot .header {
    left: 10%;
    width: calc(100% - calc(2*10%));
    display: flex;
    font-size: 0.75em;
    height: 10%;
    top: -10%;
    align-items: center; }
  .VideoPlayer.hotspot video {
    height: 260px;
    width: 462px;
    cursor: pointer;
    pointer-events: all; }
  .VideoPlayer.hotspot .caption {
    position: absolute;
    bottom: 0px;
    margin: 0;
    left: 16%;
    width: calc(100% - calc(2*16%));
    height: 2.1em;
    background-image: url("images/BackgroundAudioCC.svg");
    background-size: 100% 100%; }
    .VideoPlayer.hotspot .caption p {
      height: 100%;
      position: relative;
      top: 0.15em;
      padding: 0 60px; }
  .VideoPlayer.hotspot.bottom-right .container {
    bottom: 138px;
    right: 93px; }
  .VideoPlayer.hotspot.bottom-left .container {
    bottom: 140px;
    left: 60px; }
  .VideoPlayer.hotspot.top-right .container {
    top: 120px;
    right: 60px; }
  .VideoPlayer.hotspot.top-left .container {
    top: 120px;
    left: 60px; }

.VideoPlayer.hotspot .mobile-play-button-background, .VideoPlayer.fullscreen .mobile-play-button-background, .VideoPlayer .mobile-play-button-background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.75);
  pointer-events: all;
  z-index: 13; }

.VideoPlayer.hotspot button.mobilePlayButton.playButton--mobileStart, .VideoPlayer.fullscreen button.mobilePlayButton.playButton--mobileStart, .VideoPlayer button.mobilePlayButton.playButton--mobileStart {
  width: 30%;
  height: 0;
  padding-bottom: 30%;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 12; }

@media screen and (max-width: 2000px) {
  .VideoPlayer.hotspot .caption.external {
    font-size: 1.3em; } }

@media screen and (max-height: 840px) and (min-width: 1150px) {
  .VideoPlayer {
    margin: 134px 0px; }
    .VideoPlayer video, .VideoPlayer .player-content-frame {
      width: 860px;
      height: 484px; }
    .VideoPlayer .caption {
      left: 110px;
      width: calc(100% - calc(2*110px));
      font-size: 1.2em; }
    .VideoPlayer .header {
      font-size: 1.0em;
      left: 110px;
      width: calc(100% - calc(2*110px)); }
    .VideoPlayer button {
      width: 40px;
      height: 40px; }
    .VideoPlayer button.closeButton {
      top: -39px;
      right: -12px; }
    .VideoPlayer.fullscreen .controls .closeButton {
      top: 70px;
      right: 15px; }
    .VideoPlayer.fullscreen .controls .pauseButton, .VideoPlayer.fullscreen .controls .playButton {
      left: 13.5px; }
    .VideoPlayer.fullscreen .controls .nudgeBackButton {
      right: 13.5px; }
    .VideoPlayer.fullscreen .controls .playButton, .VideoPlayer.fullscreen .controls .pauseButton, .VideoPlayer.fullscreen .controls .nudgeBackButton {
      bottom: 73.5px; } }

@media (max-width: 1150px), (max-height: 740px) {
  .VideoPlayer video, .VideoPlayer .player-content-frame {
    width: 640px;
    height: 360px; }
  .VideoPlayer button {
    width: 40px;
    height: 40px; }
    .VideoPlayer button.closeButton {
      top: -30px;
      right: -11px; }
    .VideoPlayer button.pauseButton {
      left: -6px;
      bottom: -16px; }
    .VideoPlayer button.playButton {
      left: -6px;
      bottom: -16px; }
    .VideoPlayer button.nudgeBackButton {
      right: -6px;
      bottom: -16px; }
  .VideoPlayer .caption {
    left: 8%;
    width: calc(100% - calc(2*8%));
    font-size: 1em; }
  .VideoPlayer .header {
    font-size: 0.9em;
    left: 90px;
    width: calc(100% - calc(2*90px)); }
  .VideoPlayer.fullscreen .header {
    font-size: 1.0em;
    height: 35px; }
  .VideoPlayer.fullscreen .caption {
    font-size: 1.07em; }
  .VideoPlayer.fullscreen .controls button {
    bottom: 73.5px; }
  .VideoPlayer.fullscreen .controls .closeButton {
    top: 70px;
    right: 15px; }
  .VideoPlayer.fullscreen .controls .pauseButton, .VideoPlayer.fullscreen .controls .playButton {
    left: 14px; }
  .VideoPlayer.fullscreen .controls .nudgeBackButton {
    right: 14px; }
  .VideoPlayer.fullscreen .controls .playButton, .VideoPlayer.fullscreen .controls .pauseButton, .VideoPlayer.fullscreen .controls .nudgeBackButton {
    bottom: 73.5px; }
  .VideoPlayer.hotspot .caption.external {
    font-size: 1.0em; }
  .VideoPlayer.hotspot video {
    height: 247px;
    width: 440px; } }

@media screen and (max-height: 570px) {
  .VideoPlayer {
    margin: 110px; }
    .VideoPlayer video, .VideoPlayer .player-content-frame {
      width: 448px;
      height: 252px; }
    .VideoPlayer .caption {
      left: 8%;
      width: calc(100% - calc(2*8%));
      font-size: 0.7em; }
    .VideoPlayer .header {
      font-size: 0.7em;
      left: 20px;
      width: calc(100% - calc(2*20px)); }
  .VideoPlayer.hotspot .header {
    font-size: 0.4rem; }
  .VideoPlayer.hotspot .caption.external {
    font-size: 0.85em; }
  .VideoPlayer.hotspot.bottom-right .container {
    bottom: 85px;
    right: 23px; }
  .VideoPlayer.hotspot video {
    height: 171px;
    width: 304px; } }

@media screen and (max-height: 400px) {
  .VideoPlayer {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    margin: 0; }
    .VideoPlayer video, .VideoPlayer .player-content-frame {
      width: 485.33px;
      height: 273px; }
    .VideoPlayer .caption {
      left: 8%;
      width: calc(100% - calc(2*8%));
      font-size: 0.7em; }
    .VideoPlayer .header {
      font-size: 0.7em;
      left: 20px;
      width: calc(100% - calc(2*20px)); }
  .VideoPlayer.hotspot .header {
    font-size: 0.4rem; }
  .VideoPlayer.hotspot .caption.external {
    font-size: 0.85em; }
  .VideoPlayer.hotspot.bottom-right .container {
    bottom: 85px;
    right: 23px; }
  .VideoPlayer.hotspot video {
    height: 156px;
    width: 277.33px; } }

.AudioPlayer {
  pointer-events: all;
  position: relative;
  margin: 150px auto;
  display: flex;
  justify-content: center; }
  .AudioPlayer .container {
    position: relative; }
  .AudioPlayer .content {
    width: 1080px;
    height: 608px;
    display: block;
    background-color: black; }
    .AudioPlayer .content img {
      width: 100%;
      height: 100%; }
  .AudioPlayer .overlay {
    position: absolute;
    top: -54px;
    left: -11px;
    width: 1102px;
    height: 740px;
    pointer-events: none;
    content: url("images/VideoWindow.svg"); }
  .AudioPlayer .header {
    font-size: 1.5em;
    font-family: xenera, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #99f2db;
    position: absolute;
    top: -50px;
    left: 110px;
    width: calc(100% - 220px);
    height: 50px;
    display: table;
    margin: 0;
    padding: 0;
    letter-spacing: 2px; }
    .AudioPlayer .header p {
      display: table-cell;
      vertical-align: middle;
      margin: 0;
      padding: 0; }
    .AudioPlayer .header .title {
      text-align: right;
      padding-right: 20px;
      color: #a7a72d;
      text-transform: uppercase; }
    .AudioPlayer .header .subtitle {
      text-align: left;
      text-transform: capitalize; }
  .AudioPlayer .caption {
    font-size: 1.5em;
    font-family: program, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #99f2db;
    position: absolute;
    left: 110px;
    width: calc(100% - 220px);
    height: 68px;
    display: table;
    margin: 0;
    padding: 0;
    letter-spacing: 2px; }
    .AudioPlayer .caption p {
      display: table-cell;
      text-align: center;
      vertical-align: middle;
      margin: 0;
      padding: 0; }
  .AudioPlayer button {
    position: absolute;
    z-index: 100;
    display: block;
    color: transparent;
    background-color: transparent;
    background-size: 100% 100%;
    width: 50px;
    height: 50px;
    text-align: center;
    border: none;
    cursor: pointer; }
    .AudioPlayer button:focus {
      outline: none; }
    .AudioPlayer button.closeButton {
      background-image: url("images/button-close.png");
      top: -50px;
      right: -6px; }
    .AudioPlayer button.pauseButton {
      background-image: url("images/button-pause.png");
      left: -6px;
      bottom: -26px; }
    .AudioPlayer button.playButton {
      background-image: url("images/button-play.png");
      left: -6px;
      bottom: -26px; }
  .AudioPlayer .ProgressBar {
    position: absolute;
    bottom: 0px;
    left: 30px;
    width: calc(100% - 30px);
    height: 6px; }
  .AudioPlayer.bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    margin: 0;
    height: 100%;
    pointer-events: none; }
    .AudioPlayer.bottom .container {
      width: 68%;
      background-image: url("images/BackgroundAudioCC.svg");
      background-size: 100% 100%;
      bottom: -5px;
      position: absolute;
      height: 3.15em;
      font-size: 0.95em; }
    .AudioPlayer.bottom .caption {
      height: 100%;
      left: 10%;
      width: calc(100% - 2*10%); }
      .AudioPlayer.bottom .caption p {
        position: relative;
        top: 0.1em; }
    .AudioPlayer.bottom.modal {
      pointer-events: all; }

@media (max-width: 2000px) {
  .AudioPlayer .content {
    width: 640px;
    height: 360px; }
  .AudioPlayer .overlay {
    top: -32px;
    left: -8px;
    width: 656px;
    height: 438px; }
  .AudioPlayer button {
    width: 40px;
    height: 40px; }
    .AudioPlayer button.closeButton {
      top: -30px;
      right: -6px; }
    .AudioPlayer button.pauseButton {
      left: -6px;
      bottom: -16px; }
    .AudioPlayer button.playButton {
      left: -6px;
      bottom: -16px; }
  .AudioPlayer .caption {
    left: 110px;
    width: calc(100% - 220px);
    height: 43px;
    font-size: 1.3em; }
  .AudioPlayer .header {
    font-size: 1em;
    top: -30px;
    left: 110px;
    width: calc(100% - 220px);
    height: 30px; } }

@media screen and (max-width: 1360px), (max-height: 740px) {
  .AudioPlayer .caption {
    font-size: 1.0em; } }

.ModelViewer {
  width: 100%;
  height: 100%; }
  .ModelViewer button.closeButton {
    position: absolute;
    top: 100px;
    right: 100px; }
  .ModelViewer .model-loading-spinner svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%); }

.qualitySelector {
  position: relative;
  color: #99f2db;
  pointer-events: all;
  z-index: 1000; }
  .qualitySelector p {
    margin: 5px 0; }
  .qualitySelector ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row-reverse; }
  .qualitySelector .videoQUalityOptions {
    margin-bottom: 10px; }
    .qualitySelector .videoQUalityOptions button {
      width: 100%;
      background: none;
      background-color: #17143c;
      border: 1px solid #0edbc2;
      padding: 7px 12px;
      color: #0edbc2;
      text-transform: uppercase;
      cursor: pointer; }
    .qualitySelector .videoQUalityOptions .active {
      background-color: #0edbc2;
      color: #17143c; }
    .qualitySelector .videoQUalityOptions button:hover {
      background-color: #0edbc2;
      color: #17143c; }
  .qualitySelector li {
    list-style: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
    flex-grow: 1; }
    .qualitySelector li:nth-child(1) button {
      border-radius: 0px 6px 6px 0px; }
    .qualitySelector li:nth-child(3) button {
      border-radius: 6px 0px 0px 6px; }
    .qualitySelector li img {
      vertical-align: middle;
      height: 28px; }
    .qualitySelector li span:hover {
      color: black;
      background-color: #99f2db; }
    .qualitySelector li .videoQualityButton {
      display: flex;
      flex-direction: row-reverse;
      width: 64px;
      background: no-repeat;
      border: none;
      border-radius: 6px !important;
      background-color: #17143c;
      color: #0edbc2;
      font-size: 16px;
      padding: 5px 15px;
      font-family: Exo2;
      font-weight: 600;
      cursor: pointer;
      pointer-events: all;
      overflow: hidden;
      transition: all 0.12s; }
      .qualitySelector li .videoQualityButton p {
        width: auto;
        min-width: 140px;
        padding: 0;
        margin: 0;
        padding-right: 10px;
        line-height: 1.5rem;
        text-transform: uppercase; }
      .qualitySelector li .videoQualityButton img {
        height: 27px; }
    .qualitySelector li .videoQualityButton:hover,
    .qualitySelector li .videoQualityButton:focus {
      width: 200px;
      background-color: #17143c;
      color: #0edbc2; }
    .qualitySelector li .videoQualityButton:active {
      outline: none; }
    .qualitySelector li .videoQualityButton:focus {
      outline: none; }
  .qualitySelector .video-quality-instructions {
    position: absolute;
    left: 100%;
    top: -20px;
    transform: translateX(-100%) translateY(-100%);
    font-size: 1.1rem; }

.vidoeWualityWrap {
  z-index: 1000; }

.Preloader {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  overflow: hidden;
  pointer-events: none;
  background-color: #222;
  color: #68c4bc;
  display: flex;
  align-items: center;
  font-family: xenera, sans-serif;
  font-weight: 400;
  font-style: normal; }
  .Preloader .info-container {
    position: absolute;
    left: 70px;
    bottom: 70px;
    width: 25%; }
  .Preloader .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; }
  .Preloader p {
    width: 100%;
    display: block;
    text-align: left;
    font-size: 0.8em; }
  .Preloader .track {
    width: 400px;
    height: 2px;
    background-color: black; }
  .Preloader .bar {
    background-color: #68c4bc;
    height: 2px; }

.embedded-html-game iframe {
  width: 1080px;
  height: 608px; }

@media screen and (max-height: 840px) and (min-width: 1150px) {
  .embedded-html-game iframe {
    width: 860px;
    height: 484px; } }

@media (max-width: 1150px), (max-height: 740px) {
  .embedded-html-game iframe {
    width: 640px;
    height: 360px; } }

.device-instructions {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  overflow: hidden;
  pointer-events: none;
  background-color: #222;
  color: white;
  display: none;
  align-items: center;
  font-family: xenera, sans-serif;
  font-weight: 400;
  font-style: normal;
  z-index: 999;
  text-align: center; }
  .device-instructions .info-container {
    width: 85%;
    margin-top: 50px; }
  .device-instructions .container {
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly; }
  .device-instructions .logo {
    width: 80%;
    max-width: 360px; }
  .device-instructions h3 {
    font-size: 1.5em; }
  .device-instructions h2 {
    text-align: center;
    font-size: 2em; }
  .device-instructions p {
    width: 100%;
    display: block;
    text-align: left;
    font-size: 0.8em; }

.device-instructions .rotate-arrow {
  width: 55px;
  animation-duration: 2s;
  animation-name: rotateImg;
  animation-iteration-count: infinite; }

@keyframes rotateImg {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(-90deg); } }

@media screen and (max-width: 800px) and (orientation: portrait) {
  .device-instructions {
    display: flex; } }

@media (max-aspect-ratio: 1 / 1) {
  .device-instructions {
    display: flex; } }

canvas#babylon-canvas {
  width: 100%;
  height: 100%; }

.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  background: #1E0D3E;
  color: #0EDBC2; }
  .loading-screen .loading-screen__content {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .loading-screen .loading-screen__content > div, .loading-screen .loading-screen__content > h1 {
    margin: 10px; }
  .loading-screen .loading-logo-container, .loading-screen .model-loading-spinner {
    text-align: center; }
  .loading-screen .loading-logo-container > img {
    width: 80%;
    max-width: 400px; }
  .loading-screen h1 {
    font-family: "Exo2";
    text-align: center;
    margin: 0%; }

.teacher-panel {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: black;
  color: #0DDBC2;
  top: 0;
  left: 0;
  font-family: Roboto;
  overflow-y: scroll; }
  .teacher-panel button {
    font-size: 1.3rem;
    background: transparent;
    border: solid thin #0DDBC2;
    color: #0DDBC2;
    padding: 0.3rem;
    margin: 0.2rem; }
    .teacher-panel button.active, .teacher-panel button:hover {
      background: #0DDBC2;
      color: black; }
  .teacher-panel .close-icon {
    position: fixed;
    top: 15vmin;
    right: 15vmin; }
  .teacher-panel__content {
    width: 100%;
    max-width: 500px;
    margin: 0 auto; }
  .teacher-panel .destination-panel {
    margin: 1.0rem 0;
    text-align: center;
    border: solid thin #0DDBC2; }
    .teacher-panel .destination-panel p {
      padding: 0 0.4rem; }
  .teacher-panel .destination-select {
    display: flex; }
    .teacher-panel .destination-select > * {
      padding: 0.3rem;
      width: 50%;
      border: solid thin #0DDBC2; }
  .teacher-panel .destination-go-btn {
    padding: 0.5rem;
    font-size: 1.6rem;
    margin: 0.5rem auto;
    width: 300px; }
    .teacher-panel .destination-go-btn.disabled {
      color: gray;
      border: solid thin gray; }
    .teacher-panel .destination-go-btn.disabled:hover {
      background: gray;
      color: black; }
  .teacher-panel .mission-row {
    border: solid thin #0DDBC2;
    width: 100%;
    padding: 0.5rem; }
    .teacher-panel .mission-row .btn-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap; }

.minecraft-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0DDBC2;
  background: rgba(0, 0, 0, 0.65);
  pointer-events: all; }
  .minecraft-dialog .minecraft-icons {
    display: flex;
    flex-direction: row;
    gap: 15px; }
    .minecraft-dialog .minecraft-icons span {
      display: inline-block;
      background-repeat: no-repeat; }
    .minecraft-dialog .minecraft-icons .minecraft-icon {
      width: 62px;
      height: 67px;
      background-size: 100%;
      background-image: url("images/minecraft.png"); }
    .minecraft-dialog .minecraft-icons .whimc-icon {
      width: 145px;
      height: 67px;
      background-size: 100%;
      background-image: url("images/whimc-logo.png");
      background-position: center;
      margin-left: 5px; }
    .minecraft-dialog .minecraft-icons .astronaut-icon {
      width: 66px;
      height: 67px;
      background-size: 100%;
      background-image: url("images/astronaut.png"); }
  .minecraft-dialog p {
    font-size: 1.1rem;
    margin-top: 10px;
    text-align: left; }
  .minecraft-dialog form {
    position: relative;
    padding: 1.0rem;
    border: solid thin #0DDBC2;
    width: 90%;
    max-width: 720px;
    background: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 12px; }
  .minecraft-dialog button {
    display: block;
    background-color: #17143c;
    color: #0DDBC2;
    border: 1px solid #0DDBC2;
    border-radius: 6px;
    cursor: pointer; }
  .minecraft-dialog .close-btn {
    padding: 8px 13px;
    font-size: 1.2rem;
    position: absolute;
    right: 10px;
    top: 10px; }
  .minecraft-dialog .minecraft-actions {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 100px; }
    .minecraft-dialog .minecraft-actions input[type="text"] {
      width: 100%;
      height: 35px;
      background-color: #ffffff;
      border: 1px solid #0DDBC2;
      border-radius: 6px 0px 0px 6px;
      padding: 0px 15px; }
    .minecraft-dialog .minecraft-actions button {
      border-radius: 0px 6px 6px 0px; }

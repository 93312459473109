.rendered-react-keyed-file-browser a {
  text-decoration: none;
  color: #1976d2;
  font-size: 20px;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  height: 1.5em;
  display: inline-flex;
  align-items: center;
  letter-spacing: 0.02857em;
  word-spacing: 0.1em;
}

.rendered-react-keyed-file-browser tr .MuiSvgIcon-root {
  height: 20px;
}

.rendered-react-keyed-file-browser a[role="button"] {
  border: 0;
  cursor: pointer;
  display: inline-flex;
  outline: none;
  position: relative;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  justify-content: center;
  text-decoration: none;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;

  padding: 18px 10px;
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;

  color: rgba(0, 0, 0, 0.87);
  box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12);
  background-color: #e0e0e0;
}

.rendered-react-keyed-file-browser {
  height: 100%;
  overflow: hidden;
}

.rendered-file-browser {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.rendered-file-browser .files {
  height: 100%;
  overflow-y: scroll;
}

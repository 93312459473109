* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html, body, #root {
  width: 100%;
  height: 100%;
}

#root {
  display: flex;
}

#editor {
  width: 50%;
}

#preview {
  width: 50%;
}

.ScenePreview {
  height: 100%;
  background-color: black;
}

.ScenePreview:focus {
  outline: none;
}

.Scene {
  position: relative;
  height: 100%;
}
